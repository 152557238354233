import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Neuehrenfeld: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Neuehrenfeld,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Neuehrenfeld"
          points="487.8,558.3 460,590 438,568 412,554 416,548 396,534 411,520 407,510 431,496 431.7,495.6 448,530 472,532 478,546 488,558"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 422.5078 540.3496)"
          textRendering="geometricPrecision"
        >
          <tspan x="0" y="0">
            Neu-
          </tspan>
          <tspan x="0" y="14.4">
            ehrenfeld
          </tspan>
        </text>
      </g>
    </>
  );
});
